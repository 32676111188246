import routes from "@/router/routes";
import {cript} from '@/functions/criptografia.js';
import SaldoEstoqueController from "@/controllers/SaldoEstoqueController";
/* ----------------------------------------------------------------------------------------------------- */
/* ---- Funções Básicas de Request --------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
async function basicRequestFunc( Controller, payload ) {
    return Controller.index( payload );
}

async function formRequestFunc( Controller, form, empId, filtro = null ) {
    const request = {
        EMP_ID: empId || 1,
        LIMIT: 20,
        FILTRO: filtro,
        ...form
    };
    const response = await Controller.index( request );
    return response;
}
/* ----------------------------------------------------------------------------------------------------- */

export const reqFunc = {
    /* ------------------------------------------------------------------------------------------------- */
    /* ---- Funções de Request Compostas  -------------------------------------------------------------- */
    /* ------------------------------------------------------------------------------------------------- */

    async getFormasPagamento ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.FormasPagamentoController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.FormasPagamentoController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter formas de pagamento." );
        }
    },

    async getBancos ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.BancosController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.BancosController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter bancos." );
        }
    },
    async getClientes ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.ClientesController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.ClientesController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter clientes." );
        }
    },
     async getVeiculos ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.VeiculosController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.VeiculosController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter clientes." );
        }
    },
    async getFornecedores ( form, empId ) {
        try {
            if (form) {
                return await formRequestFunc( routes.FornecedoresController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.FornecedoresController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter fornecedores." );
        }
    },
    async getGrupos ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.GruposController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.GruposController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter grupos." );
        }
    },
    async getLocais ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.LocaisController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.LocaisController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter locais." );
        }
    },
    async getEstados ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.EstadosController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.EstadosController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter estados." );
        }
    },
    async getRepresentantes ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.ColaboradoresController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.ColaboradoresController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter representantes." );
        }
    },
    async getAreas ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.AreasController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.AreasController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter areas." );
        }
    },
    async getSubgrupos ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.SubGruposController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.SubGruposController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter subgrupos." );
        }
    },
    async getServicos ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.ServicosController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.ServicosController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter servicos." );
        }
    },
    async getProdutos ( form = null, empId = null, prodId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.ProdutoController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1, ...(prodId && { PROD_ID: prodId }) };
                return await basicRequestFunc( routes.ProdutoController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter produtos." );
        }
    },
    async getHistoricos ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.HistoricosController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.HistoricosController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter históricos." );
        }
    },
    async getHistoricosPadrao ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.HistoricoPadraoController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.HistoricoPadraoController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter históricos padrão." );
        }
    },
    async getUsuarios ( form = null, empId = null ) {
        try {
            if (form) {
                return await formRequestFunc( routes.UsuariosController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.UsuariosController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter usuários." );
        }
    },

    async getOrdemServicoProdutos ( form = null, empId = null) {
        try {
            return await formRequestFunc( routes.OrdemServicosProdutosController, form, empId );
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter produtos." );
        }
    },

    async getMovimentacoesBancarias (form = null, empId = null) {
        try {
            // console.log('form:', form);
            if (form) {
                return await formRequestFunc( routes.MovimentacaoBancariaController, form, empId );
            } else {
                const payload = { EMP_ID: empId || 1 };
                return await basicRequestFunc( routes.MovimentacaoBancariaController, payload );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter as movimentações bancárias do banco selecionado." );
        }
    },

    async getMovimentacoesBancariasBS (form = null, empId = null) {
        try {
            if (form) {
                const request = {
                    EMP_ID: empId || 1,
                    ...form
                };
                
                const response =  await routes.MovimentacaoBancariaController.buscarMovBS( request );
                return response;
            } else {
                this.$snotify.error( "Formulário de busca de Movimentações Bancárias do BS inválido." );
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter as movimentações bancárias do banco selecionado." );
        }
    },

    /* ------------------------------------------------------------------------------------------------- */
    /* ---- Funções de Request Específicas ------------------------------------------------------------- */
    /* ------------------------------------------------------------------------------------------------- */
    async getDados ( form, action ) {
        try {
            const data = await routes.RelatoriosController[ action ]( form );
            return data;
        } catch ( error ) {
            this.$snotify.error( error.msg || 'Erro ao obter dados do relatório.' );
            return null;
        }
    },
    async getEmpresa ( stateId, empId ) {
        try {
            let response = await routes.EmpresasController.index();
            response.forEach( item => {
                item.id = cript.decryptData( stateId );
            });
            return response.find( item => item.EMP_ID == empId );
        } catch ( error ) {
            console.error( error );
            this.$snotify.error( error.msg || 'Erro ao obter dados da empresa.' );
            return null;
        }
    },

    getProdutoByCbar( prcbId ) {
        return routes.ProdutoController.searchProdCod({ PRCB_EMP_ID: 1, PRCB_ID: prcbId })
        .then( data => {
            if(!data.code || data.code !== 500){
                return data;
            } else {
                return null;
            }
        }).catch( error => {
            console.error( error );
            this.$snotify.error( 'error busca produtos por CBAR:', error );
        });
    },
    async getProdutosGrid ( prodId, empId ) {
        try {
            const form = {
                EMP_ID: empId,
                TIPO_CONS: "COD",
                ID: prodId
            };
            const response = await routes.GradeProdutosController.filtro( form );
            if ( response.code == 500 ) {
                this.$snotify.warning( "Nenhuma grade cadastrada." );
                return false
            }
            response.forEach(( item, index ) => item.ORDEM = index % 2 == 0 ? 0 : 1 );
            return response;
        } catch ( error ) {
            this.$snotify.error( error.msg );
            return false
        }
    },
    async getProdutoEstoqueData ( empId, prodId ){
        try {
            const response = await SaldoEstoqueController.getProdCustoMedio({ SALD_PROD_ID: prodId, SALD_PROD_EMP_ID: empId });
            return { 
                SALDO_FISICO: response.QTD_ESTOQUE, 
                CUSTO_MEDIO: response.CUSTO_MEDIO, 
                SALDO_FINANCEIRO: response.CUSTO_MEDIO * response.QTD_ESTOQUE 
            }
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter dados de estoque do produtos." );
        }
    },

    async getMarcas () {
        try {
            const response = await routes.MarcaController.index();
            return response;
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter marcas." );
        }
    },

    async getUnidades(req) {
        try {
            const response = await routes.UnidadesController.index(req);
            return response;
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter unidades." );
        }
    },

    async getBaixaRapida( empId = 1 ) {
        try {
            const request = { CMPD_EMP_ID: empId}
            const response = await routes.BaixaContasPagarController.baixaRapidaContas( request );
            return response;
        } catch ( error ) {
            this.$snotify.error( error.msg || "Erro ao obter baixa rápida." );
        }
    },
    /* ------------------------------------------------------------------------------------------------- */
}