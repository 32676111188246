class LabelDictionary { 
    constructor() { 
        this.acrescimo = { text: 'Acréscimo' },
        this.ano = { text: 'Ano', 
            inicial: { text: 'Ano Inicial', short: 'Ano Ini.' }, 
            final: { text: 'Ano Final', short: 'Ano Fin.' } },
        this.area = { text: 'Área' },
        this.arquivo = { text: 'Arquivo' },
        this.baixar = { text: 'Baixa',
            contas: { text: 'Baixar Contas', short: 'Bai. Contas' },
            rapida: { text: 'Baixa Rápida', short: 'Bai. Ráp.'},
        },
        this.banco = { text: 'Banco', short: 'Bco.' },
        this.botao = { text: 'Botão', short: 'Btn' },
        this.buscar = { text: 'Buscar',
            arquivo: { text: 'Buscar Arquivo',
                ofx: { text: 'Buscar Arquivo OFX' },
                pdf: { text: 'Buscar Arquivo PDF' },
             },
         },
         this.caixa = { text: 'Caixa', short: 'Cx.',
            banco: { text: 'Caixa/Banco', short: 'Cx/Bco' },
        },
        this.cancelar = { text: 'Cancelar',
            cadastro: { text: 'Cancelar Cadastro' },
            dados: { text: 'Cancelar Dados' },
            registro: { text: 'Cancelar Registro' },
        },
        this.carregar = { text: 'Carregar',
            arquivo: { text: 'Carregar Arquivo' },
            dados: { text: 'Carregar Dados' },
         },
        this.cidade = { text: 'Cidade' },
        this.cliente = { text: 'Cliente' },
        this.codigo = { text: 'Código', short: 'Cód.',
            bancario: { text: 'Código Bancário', short: 'Cód. Bco.' },
        },
        this.colaborador = { text: 'Representante', short: 'Repr.' },
        this.complemento = { text: 'Complemento' },
        this.conciliacao = { text: 'Conciliação', short: 'Con.',
            codigo: { text: 'Código Conciliação', short: 'C.Cód.' },
        },
        this.conciliar = { text: 'Conciliar',
            selecionados: { text: 'Conciliar Selecionados' }
        },
        this.crtc = { text: 'Conhecimento de Transporte', short: 'CRTC' },
        this.data = { text: 'Data', 
            inicial: { text: 'Data Inicial', short: 'Dta. Ini.' }, 
            final: { text: 'Data Final', short: 'Dta. Fin.' },
            baixa: { text: 'Data Baixa', 
                inicial: { text: 'Data Baixa Inicial', short: 'Dta. Bai. Ini.' }, 
                final: { text: 'Data Baixa Final', short: 'Dta. Bai. Fin.' },
            },
            emissao: { text: 'Data Emissão', 
                inicial: { text: 'Data Emissão Inicial', short: 'Dta. Emiss. Ini.' }, 
                final: { text: 'Data Emissão Final', short: 'Dta. Emiss. Fin.' },
            },
            movimento: { text: 'Data Movimento', 
                inicial: { text: 'Data Movimento Inicial', short: 'Dta. Mov. Ini.' }, 
                final: { text: 'Data Movimento Final', short: 'Dta. Mov. Fin.' },
            },
            pagamento: { text: 'Data Pagamento', short: 'Data Pgmt.' },
            vencimento: { text: 'Data Vencimento', 
                inicial: { text: 'Data Vencimento Inicial', short: 'Dta. Venc. Ini.' }, 
                final: { text: 'Data Vencimento Final', short: 'Dta. Venc. Fin.' },
            },
        },
        this.desconciliar = { text: 'Desconciliar' },
        this.dias = {  text: 'Dias',
            futuro: { text: 'Dias Futuro' },
        },
        this.diferenca = { text: 'Diferença' },
        this.documento = { text: 'Documento', short: 'Doc.',
            auxiliar: { text: 'Documento Auxiliar', short: 'Doc. Aux.' },
            cheque: { text: 'Documento/Cheque', short: 'Doc./Cheque' },
            cpf: { text: 'Cadastro de Pessoa Física', short: 'CPF',
                cnpj: { text: 'CPF/CNPJ' },
            },
            cnpj: { text: 'Cadastro Nacional de Pessoa Jurídica', short: 'CNPJ',
                cpf: { text: 'CNPJ/CPF' },
            },
            fiscal: { text: 'Documento Fiscal', short: 'Doc. Fiscal' },
        },
        this.estado = { text: 'Estado' },
        this.estatistica = { text: 'Estatística', short: 'Estat.' },
        this.fornecedor = { text: 'Fornecedor', short: 'Forn.',
            frete: { text: 'Fornecedor/Frete', short: 'Forn/Frete' },
         },
        this.frete = { text: 'Frete', short: 'Frt.',
            transportadora: { text: 'Frete/Transportadora', short: 'Frete/Tranp.' },
        },
        this.forma = {  text: 'Forma',
            pagamento: { text: 'Forma de Pagamento', short: 'F. Pagto.' },
        },
        this.grupo = { text: 'Grupo' },
        this.historico = { text: 'Histórico', short: 'Hist.',
            veicular: { text: 'Histórico Veicular', short: 'Hist. Veic.' },
            padrao: { text: 'Histórico Padrão', short: 'Hist. Padrão' },
        },
        this.inscricao = { text: 'Inscrição', 
            estatual: { text: 'Inscrição Estadual', short: 'IE' },
        },
        this.juros = { text: 'Juros',
            previstos: { text: 'Juros Previstos', short: 'Juros Prev.' },
            titulos: { text: 'Juros + Títulos' },
        },
        this.local = { text: 'Local',
            estoque: { text: 'Local Estoque' },
        },
        this.marca = { text: 'Marca' },
        this.mes = { text: 'Mês' },
        this.meses = { text: 'Meses',
            analise: { text: 'Meses de Análise' },
        },
        this.modelo = { text: 'Modelo' },
        this.nota = { text: 'Nota',
            fiscal: { text: 'Nota Fiscal' },
            compra: { text: 'Nota de Compra' },
         },
        this.numero = { text: 'Número',
            movimento: { text: 'Número Movimento', short: 'Num. Móv.' },
         },
        this.observacao = { text: 'Observação', short: 'Obs.' },
        this.ordem = { text: 'Ordem' },
        this.ordenacao = { text: 'Ordenação' },
        this.processar = { text: 'Processar',
            dados: { text: 'Processar Dados' },
        },
        this.plano = { text: 'Plano',
            contas: { text: 'Plano de Contas' },
         },
        this.processar = { text: 'Processar' },
        this.procurar = { text: 'Procurar',
            arquivo: { text: 'Procurar Arquivo',
                ofx: { text: 'Procurar Arquivo OFX' },
                pdf: { text: 'Procurar Arquivo PDF' },
             },
         },
        this.ramo = {  text: 'Ramo', 
            atividade: { text: 'Ramo de Atividade', short: 'R. Atv.' },
         },
        this.regime = { text: 'Regime' },
        this.representante = { text: 'Representante' },
        this.saldo = { text: 'Saldo' },
        this.salvar = { text: 'Salvar',
            cadastro: { text: 'Salvar Cadastro' },
            dados: { text: 'Salvar Dados' },
            registro: { text: 'Salvar Registro' },
         },
        this.selecionar = { text: 'Selecionar', 
            todas: { text: 'Selecionar Todas' }, 
            limpar: { text: 'Limpar Seleção' }
         },
        this.subgrupo = { text: 'Subgrupo' },
        this.texto = { text: 'Texto' },
        this.telefone = { text: 'Telefone', short: 'Fone',
            celular: { text: 'Celular', short: 'Cel' },
            fax: { text: 'Fax' },
         },
        this.tipo = { text: 'Tipo',
            conta: { text: 'Tipo de Conta' },
            movimento: { text: 'Tipo de Movimento', short: 'Tipo Mov.' },
            pessoa: { text: 'Tipo Pessoa' },
            relatorio: { text: 'Tipo de Relatório', short: 'Tipo Rel.' },
         },
        this.total = { text: 'Total',
            baixas: { text: 'Total Baixas', short: 'Tot. Bai.' },
            bs: { text: 'Total BS' },
            ofx: { text: 'Total OFX' },
         },
        this.uf = { text: 'Unidade Federativa', short: 'UF' },
        this.unidade = { text: 'Unidade' },
        this.valor = { text: 'Valor',
            baixa: { text: 'Valor Baixa', short: 'Vlr. bai.' }, 
            inicial: { text: 'Valor Inicial', short: 'Vlr. Ini.' }, 
            final: { text: 'Valor Final', short: 'Vlr. Fin.' },
            titulo: { text: 'Valor Título', short: 'Vlr. Tít.' }, 
            total: { text: 'Valor Total', short: 'Vlr. Tot.' } },
        this.veiculo = { text: 'Veículos' },
        this.visualizacao = { text: 'Visualização' }
     }
 }

const labelDict = new LabelDictionary();
export default labelDict;
