import api from '../config/api';
class MovimentoDiarioController {
  /* eslint-disable */
  async getLength(req, res){
    try{ 
      const total = await api.post(`/movdiario/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw  error;
    }
  }
  async index(req) {
    const defaultItems = {ORDENACAO:  "MOVD_DTA_MOV ASC"}
    const form = {...defaultItems, ...req}
    try {
      let response = await api.post(`/movdiario/${form.EMP_ID}`, form);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.CNDP_ID;
          item.text = item.CNDP_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error;
    }
  }
  async created(req) {
    try {
      let response = await api.post('/movdiario', req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/movdiario/${req.MOVD_ID}/${req.MOVD_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    } 
  }
  async delete(req) {
    try {
      let response = await api.delete(`/movdiario/${req.MOVD_ID}/${req.MOVD_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/movdiario/pesquisar/${req.FILTRO}/${req.EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
}
export default new MovimentoDiarioController();