const MovimentoDiarioRoutes = [
    {
      path: "/movimentodiario",
      name: "IndexMovimentoDiario",
      meta: {
        requiresAuth: true,
        title: "Movimento Diario"
      },
      component: () => import( "@/views/movimentoDiario/indexMovimentoDiario.vue" )
    }
  ]
  
  export default MovimentoDiarioRoutes;
  